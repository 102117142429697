import React from "react"
import Seo from "../components/Seo/Seo"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import G from "../images/groenhout_g.png"
import Container from "../components/Container/Container"
import GravityForm from "../components/GravityForm"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import image from "../images/team-foto-compleet.png"

export default function StartGroenhout() {
  const seo = useStaticQuery(
    graphql`
      query StartPage {
        wpPage(slug: { eq: "goed-van-start-met-groenhout" }) {
          seo {
            metaDesc
            title
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    `
  )

  const image = seo.wpPage.featuredImage?.node.mediaItemUrl

  return (
    <>
      <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc} />
      <SmallHeroNew
        title="Goed van start met Groenhout"
        desc=""
        overlay={true}
        overlayImage={G}
        image={image !== null ? image : Image}
      ></SmallHeroNew>
      <Container styles={`py-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">
            Voordelig uw eerste woning kopen?
          </h2>
          <p className="mb-0">
            Ben je klaar om de sprong te wagen naar je allereerste woning? Wij
            begrijpen dat het een spannende reis is, vol met beslissingen en
            stappen. Daarom hebben we, in samenwerking met diverse
            onafhankelijke hypotheekadviseurs, de perfecte Startersdeal voor jou
            samengesteld.
          </p>
        </div>
      </Container>
      <Container styles={`pb-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">Wat is de startersdeal?</h2>
          <div>
            <p className="font-bold">
              Aankoopmakelaar + Hypotheekadvies
            </p>
            <ul className="list-disc">
              <li>
                Complete aankoopbegeleiding door een Groenhout Makelaar op een
                ‘No Cure, No Pay’ basis.{" "}
              </li>
              <li>Hypotheekadvies en bemiddeling bij alle geldverstrekkers</li>
              <li>
                Unieke samenwerking tussen uw makelaar en financieel adviseur
              </li>
              <li>Onbeperkt aantal bezichtigingen met de makelaar</li>
            </ul>
            <p className="font-bold">Kortom: alles wordt voor u geregeld!</p>
          </div>
        </div>
      </Container>
      <Container>
        <div className="w-full lg:w-8/12 mx-auto">
          <StaticImage src={`../images/team-foto.jpg`} />
          <figcaption className="text-center text-sm italic mt-2">
            Team Groenhout Makelaars staat voor je klaar.
          </figcaption>
        </div>
      </Container>
      <Container styles={`py-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">
            Waarom kiezen voor de Startersdeal:
          </h2>
          <p className="">
            Dankzij de nauwe samenwerking tussen onze financieel adviseur en
            makelaar ben je verzekerd van scherp financieel inzicht en de beste
            begeleiding. In de hedendaagse vastgoedmarkt is de expertise van een
            aankoopmakelaar cruciaal om een sterk bod neer te zetten.
          </p>
          <p>
            <span className="font-bold">Voordeel</span> - Maak je gebruik van
            deze deal, dan krijg je sneller een voet tussen de deur en bespaar
            je ook nog eens 800,- euro! Je ontvangt dit pakket nu voor{" "}
            <span className="font-bold">€ 3999,-</span>
          </p>
        </div>
      </Container>
      <Container styles={`pb-16 px-8 lg:px-0`}>
        <div className="w-full lg:w-1/2 lg:w-8/12 mx-auto">
          <h2 className="text-blue text-4xl">Meer weten?</h2>
          <p className="">
            Wil je gebruikmaken van de Startersdeal of wil je meer weten over
            deze actie? Vul dan onderstaand formulier in. Wij nemen zo spoedig
            mogelijk contact met je op.
          </p>
          <div className="border bg-white border-light p-5">
            <GravityForm id={"6"} colorClass={`bg-light`} />
          </div>
        </div>
      </Container>
    </>
  )
}
